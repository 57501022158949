import React from 'react';
import bindAll from 'lodash.bindall';
import classNames from 'classnames';
import logoIcon from '../../../static/digitane.svg';
import townLogo from '../../../static/town.png';
import c3Storage from '../../lib/c3/c3-storage';
import styles from './c3-project-list.css';
import Box from '../box/box.jsx';
import c3User from '../../lib/c3/c3-user.js';
import headerStyles from '../../components/modal/modal.css';
import menuBarStyles from '../../components/menu-bar/menu-bar.css';
import ConfirmModalComponent from './confirm/confirm-modal.jsx';

import deleteIcon from './ico-trashbox.svg';
import disableDeleteIcon from './ico-trashbox2.svg';
import shareIcon from './ico-link.svg';
import projectIcon from './ico-myproject.svg';
import {err500} from '../../lib/c3/c3-error';

class C3Project extends React.Component{
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleStopShare',
            'handleDelete'
        ]);
        this.state = {
            isDeleted: false,
            isPublic: props.project.isPublic,
            isOpenStopShareConfirm: false,
            isOpenDeleteProjectConfirm: false
        };
    }

    handleStopShare () {
        const projectId = this.props.project.id;
        c3Storage.getProjectData(projectId, this.state.isPublic)
            .then(data => c3Storage.updateVisibilityProject(data, false, projectId))
            .then(() => this.setState(state => ({...state, isPublic: false, isOpenStopShareConfirm: false})))
            .catch(err => {
                err500();
            });
    }

    handleDelete () {
        c3Storage.deleteProject(this.props.project.id, this.state.isPublic)
            .then(() => this.setState(state => ({...state, isDeleted: true, isOpenDeleteProjectConfirm: false})))
            .catch(err => {
                err500();
            });
    }

    render () {
        const {
            project
        } = this.props;
        return !this.state.isDeleted && (
            <>
                <ConfirmModalComponent
                    isOpen={this.state.isOpenStopShareConfirm}
                    onClickNegativeButton={() => {
                        this.setState(state => ({...state, isOpenStopShareConfirm: false}));
                    }}
                    onClickPositiveButton={this.handleStopShare}
                    positiveButtonLabel={'きょうゆうをやめる'}
                    negativeButtonLabel={'キャンセル'}
                    message={'きょうゆうをやめますか？'}
                />
                <ConfirmModalComponent
                    isOpen={this.state.isOpenDeleteProjectConfirm}
                    onClickNegativeButton={() => {
                        this.setState(state => ({...state, isOpenDeleteProjectConfirm: false}));
                    }}
                    onClickPositiveButton={this.handleDelete}
                    positiveButtonLabel={'さくじょします'}
                    negativeButtonLabel={'キャンセル'}
                    message={'ほんとうにさくじょしますか？'}
                />
                <li className={styles.project}>
                    <div className={styles.projectCard}>
                        <img
                            src={`https://digitane.jp/online/dis-pic/${project.missionId}.png`}
                            alt="thumbnail"
                            className={styles.thumbnail}
                        />
                        <div className={styles.projectInfo}>
                            <p className={styles.ip}>{project.ip}</p>
                            <a
                                href={`/?projectId=${project.id}`}
                                className={styles.projectTitle}
                            >{project.title}</a>
                            <p className={styles.updateAt}>
                                {project.updatedAt && project.updatedAt.toDate().toLocaleDateString()}
                            </p>
                        </div>
                    </div>
                    {this.state.isPublic &&
                    <div
                        className={styles.stopShareButton}
                        onClick={() => {
                            this.setState(state => ({...state, isOpenStopShareConfirm: true}));
                        }}
                    >
                        <img
                            src={shareIcon}
                            alt="share"
                            className={styles.shareIcon}
                        />
                        <p className={styles.shareLabel}>
                            きょうゆうちゅう
                        </p>
                    </div>
                    }
                </li>
                <img
                    src={this.state.isPublic ? disableDeleteIcon : deleteIcon}
                    alt="delete"
                    className={this.state.isPublic ? styles.disableDeleteButton : styles.deleteButton}
                    onClick={this.state.isPublic ? undefined : () => {
                        this.setState(state => ({...state, isOpenDeleteProjectConfirm: true}));
                    }}
                />
            </>
        );
    }
}

class C3ProjectList extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            projects: []
        };
        this.loadProjects();
    }

    loadProjects () {
        c3Storage.getProjectList()
            .then(projects => {
                this.setState({
                    projects: projects
                });
            });
    }

    render () {
        return (
            <Box
                dir={this.props.isRtl ? 'rtl' : 'ltr'}
                direction="column"
                grow={1}
                className={styles.box}
            >
                <div className={classNames(headerStyles.header)}>
                    <div
                        className={classNames(
                            styles.headerItem,
                            headerStyles.headerItemClose
                        )}
                    >
                        <div className={menuBarStyles.fileGroup}>
                            {c3User.onlineUser() ? (
                                <a
                                    className={classNames(
                                        menuBarStyles.menuBarItem
                                    )}
                                    href={`${c3User.onlineUrl()}/s`}
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        alt="home"
                                        className={styles.logo}
                                        draggable={false}
                                        src={townLogo}
                                    />
                                </a>
                            ) : (
                              <div
                                  className={classNames(menuBarStyles.menuBarItem)}
                              >
                                  <img
                                      alt="home"
                                      className={classNames(menuBarStyles.scratchLogo)}
                                      draggable={false}
                                      src={logoIcon}
                                  />
                              </div>
                            )}
                        </div>
                  </div>
                </div>
                <div className={styles.projectList} >
                    <div className={styles.title}>
                        <img
                            src={projectIcon}
                            alt="projects"
                        />
                        <h1 className={styles.titleLabel}>
                            MY プロジェクト
                        </h1>
                    </div>

                    {this.state.projects.map(project =>
                        (<C3Project
                            key={project.id}
                            project={project}
                        />)
                    )}
                </div>
            </Box>
        );
    }
}

export default C3ProjectList;
