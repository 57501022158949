import React from 'react';

import classNames from 'classnames';

import styles from './c3-error.css';
import Box from '../box/box.jsx';

import menuBarStyles from '../menu-bar/menu-bar.css';
import headerStyles from '../../components/modal/modal.css';
import queryString from 'query-string';

const C3Error = function () {
    const queryParams = queryString.parse(location.search);
    const errorType = queryParams.type;
    const message = function () {
        if (errorType === '404') {
            return <span>プロジェクトがきょうゆうされていないか、<br/>ページがありません</span>;
        }
        return <span>エラーがはっせいしました</span>;
    };

    return (
        <Box
            className={styles.root}
            direction="column"
            grow={1}
        >
            <div className={classNames(headerStyles.header)}>
                <div
                    className={classNames(
                        styles.headerItem,
                        headerStyles.headerItemClose
                    )}
                >
                    <img
                        alt="Scratch"
                        className={menuBarStyles.scratchLogo}
                        draggable={false}
                        src="/static/assets/1ec71e44ceb483b1c87544af48cc6f9b.svg"
                    />
                </div>
            </div>
            <div
                style={{flexGrow: 1}}
                className={styles.errorMessage}
            >
                <div className={styles.title}>
                    <h1 className={styles.titleLabel}>
                        {message()}
                    </h1>
                </div>
            </div>
        </Box>

    );
};


export default C3Error;
