import { initializeApp, getApps } from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
} from "firebase/auth";
import { isDevelopment } from "./util";
import c3User from "./c3-user";

const LOGIN_URL = "https://digitane.jp/online/login/";

class C3Auth {
    #auth;

    constructor() {
        this.#auth = null;
    }

    initialize(firebaseConfig) {
        if (getApps().length === 0) {
            initializeApp(firebaseConfig);
        }
        this.#auth = getAuth();
    }

    // TODO(c3): email, passwordを受け取って認証する
    authenticate(email = null, password = null) {
        return new Promise((resolve) => {
            const unsub = onAuthStateChanged(this.#auth, (user) => {
                if (!user) {
                    if (email && password) {
                        signInWithEmailAndPassword(this.#auth, email, password);
                        return;
                    }
                    if (isDevelopment()) {
                        const inputEmail = window.prompt(
                            "メールアドレスを入力する",
                            ""
                        );
                        const inputPassword = window.prompt(
                            "パスワードを入力する",
                            ""
                        );
                        signInWithEmailAndPassword(
                            this.#auth,
                            inputEmail,
                            inputPassword
                        );
                        return;
                    }
                    window.location.href = LOGIN_URL;
                    return;
                } else if (user) {
                    if (email != null && user.email != email) {
                        signInWithEmailAndPassword(this.#auth, email, password);
                        return;
                    }
                }
                c3User.setMember(user.email);
                resolve(user.uid);
                unsub();
            });
        });
    }

    currentStatus() {
        return new Promise((resolve) => {
            const unsub = onAuthStateChanged(this.#auth, (user) => {
                unsub();
                resolve(user);
            });
        });
    }

    signOut() {
        signOut(this.#auth);
    }
}

const c3Auth = new C3Auth();

export default c3Auth;
