import React from 'react';
import bindAll from 'lodash.bindall';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import C3ProjectList from './c3-project-list.jsx';
import C3Error from './c3-error.jsx';
import queryString from 'query-string';
import c3Storage, {moveToNewProjectPage} from '../../lib/c3/c3-storage.js';
import c3Auth from '../../lib/c3/c3-auth.js';
import C3ShareToken from '../../lib/c3/c3-share-token.jsx';
import C3Api from '../../lib/c3/c3-api.js';
import { isDevelopment } from '../../lib/c3/util.js';
import { err500 } from '../../lib/c3/c3-error';
import c3User from '../../lib/c3/c3-user.js';


class C3GuiWrapper extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            initialized: false,
        };
        this.initialize()
    }

    initialize () {
        const queryParams = queryString.parse(location.search);

        if (queryParams.projectId) {
            c3Storage.loadProject(queryParams.projectId, false).then(()=>{
                this.setState({
                    initialized: true,
                })
            })
            return
        }

        // 共有されたプロジェクトを開く場合
        if (queryParams.token) {
            const [userId, projectId] = C3ShareToken.decode(queryParams.token)
            // 共有されたユーザのIDで上書きする
            c3Storage.setUserId(userId)
            c3Storage.loadProject(projectId, true).then(()=>{
                this.setState({
                    initialized: true,
                })
            })
            return
        }

        // 新しくプロジェクトを作成する場合
        var missionId = null
        var title = null
        var ip = null
        if (this.props.params) {
            missionId = this.props.params.missionId
            title = this.props.params.title
            ip = this.props.params.ip
        } else if ( isDevelopment() ) {
            missionId = window.prompt("ミッションIDを入力する", "")
            title = window.prompt("タイトルを入力する", "")
            ip = window.prompt("IPを入力する", "")
        }
        if (!missionId || !title || !ip) {
            err500()
        }
        c3Storage.createNewProject(c3Storage.userId(), missionId, title, ip)
        .then(projectId => {
            moveToNewProjectPage(projectId)
        }) .catch(() => {
            err500()
        })
    }

    render() {
        return this.state.initialized && this.props.children
    }
}

class Signout extends React.Component {
    componentWillMount () {
        isDevelopment() && c3Auth.signOut()
    }

    render() {
        return null
    }
}

class C3Wrapper extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'initialize',
        ]);
        this.state = {
            initialized: false,
            params: null,
        };
    }

    componentWillMount () {
        c3Storage.initialize(process.env.FIREBASE_CONFIG)
        c3Auth.initialize(process.env.FIREBASE_CONFIG)
        c3User.initialize(process.env.FIREBASE_CONFIG)
        const queryParams = queryString.parse(location.search)
        if (queryParams.q) {
            C3Api.acquireParameter(queryParams.q)
                .then((params) => {
                    this.setState({
                        ...this.state,
                        params: params
                    })
                    c3Auth.authenticate(params.user, params.fb).then(this.initialize);
                })
            return
        }

        c3Auth.currentStatus().then(user => {
            //ログイン状態なら、そのユーザーのまま共有作品をひらく
            //token がない場合は、有効なログイン情報がないと開けない
            if (user?.uid || !queryParams.token) {
              c3Auth.authenticate().then(this.initialize);
            //ログインユーザーがなく、token パラメータがあれば、ゲストユーザーでログイン
            } else if (queryParams.token) {
              c3Auth.authenticate(c3User.guestUser().user, c3User.guestUser().fb).then(this.initialize);
            }
        });
    }

    initialize (userId) {
        c3Storage.setUserId(userId)
        //管理者ユーザーでログインしていれば管理者フラグをセット
        c3Storage.setIsAdmin(userId);
        c3User.setUserId(userId)
        this.setState({
            ...this.state,
            initialized: true
        })
    }

    render() {
        return this.state.initialized && (
            <BrowserRouter>
                <Switch>
                    <Route exact path='/'>
                        <C3GuiWrapper params={this.state.params}>{this.props.children}</C3GuiWrapper>
                    </Route>
                    <Route exact path='/projects' component={C3ProjectList} />
                    <Route exact path='/signout' component={Signout} />
                    <Route exact path='/error' component={C3Error} />
                </Switch>
            </BrowserRouter>
        )
    }
}

export default C3Wrapper
